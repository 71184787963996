import PropTypes from "prop-types"
import React, {useEffect} from "react"
import {Grid, Modal} from "semantic-ui-react"
import Button from "../Button/button"
import ButtonPrimary from "../ButtonPrimary/buttonPrimary"
import ButtonSecondary from "../ButtonSecondary/buttonSecondary"
import {useLocation} from "react-router-dom"

import "./cookiesNotification.scss"

// TODO Priez de congédier ce composant sitôt que le nouveau compo CookiesModal aura pris ses quartiers sur l'ensemble des fronts

const CookiesNotification = (
    {cookiesAccepted, newButtons, onAcceptationClick, onConfigurationClick, onRefusalClick, opened, step},
    {texts},
) => {
    const {search} = useLocation()

    const params = new URLSearchParams(search.substring(1))
    const cookiesEnabled = params.get("cookiesEnabled")

    useEffect(() => {
        if (cookiesEnabled === "true") {
            onAcceptationClick()
        } else if (cookiesEnabled === "false") {
            onRefusalClick()
        }
    }, [cookiesEnabled, onAcceptationClick, onRefusalClick])

    return (
        <Modal className="cookies-modal" open={opened}>
            {step === "HOME" && (
                <Grid columns={2} stackable verticalAlign="top">
                    <Grid.Row className="cookies-modal-header">
                        <Grid.Column className="cookies-modal-title" width={11}>
                            <h1>{texts.cookiesStepOneTitle}</h1>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <p className="cookies-modal-consent" onClick={onAcceptationClick}>
                                {texts.cookiesWithoutAccept}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <p className="cookies-modal-paragraph">{texts.cookiesStepOneParagraph}</p>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Grid.Row className="cookies-modal-button">
                                {newButtons ? (
                                    <ButtonPrimary fluid onClick={onAcceptationClick}>
                                        {texts.cookiesAcceptAllButton}
                                    </ButtonPrimary>
                                ) : (
                                    <Button fluid onClick={onAcceptationClick} type="success">
                                        {texts.cookiesAcceptAllButton}
                                    </Button>
                                )}
                            </Grid.Row>
                            <Grid.Row className="cookies-modal-custom" onClick={onConfigurationClick}>
                                {texts.cookiesCustomLink}
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
            {step === "CONFIGURATION" && (
                <div>
                    <Grid columns={2} stackable verticalAlign="top">
                        <h1 className="cookies-modal-title">{texts.cookiesStepTwoTitle}</h1>

                        <p className="cookies-modal-paragraph">
                            {texts.cookiesStepTwoParagraph1}
                            <br />
                            {texts.cookiesStepTwoParagraph2}
                            <br />
                            <a href={texts.cookiesStepTwoParagraph3Link} target="cnil">
                                {texts.cookiesStepTwoParagraph3}
                            </a>
                        </p>
                        <h2 className="cookies-modal-title">{texts.cookiesStepTwoSubtitle}</h2>
                        <Grid.Row className="cookies-modal-row">
                            <Grid.Column width={11}>
                                <h5 className="cookies-modal-title">{texts.cookiesStepTwoTitleParameter1}</h5>
                                <p className="cookies-modal-paragraph">{texts.cookiesStepTwoTitle1Paragraph}</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="cookies-modal-row">
                            <Grid.Column width={11}>
                                <h5 className="cookies-modal-title">{texts.cookiesStepTwoTitleParameter2}</h5>
                                <p className="cookies-modal-paragraph"> {texts.cookiesStepTwoTitle2Paragraph}</p>
                                <p className="cookies-modal-paragraph">
                                    {texts.cookiesStepTwoTitle2AtInternet}
                                    <br />
                                    <a href={texts.cookiesStepTwoTitle2AtInternetLink} target="at">
                                        {texts.cookiesStepTwoTitle2AtInternetLinkName}
                                    </a>
                                </p>
                            </Grid.Column>
                            <Grid.Column stretched verticalAlign="top" width={4}>
                                <Grid columns="equal" stackable verticalAlign="top">
                                    <Grid.Row className="cookies-modal-row-buttons">
                                        <Grid.Column className="cookies-modal-row-column" mobile={16} stretched>
                                            {newButtons ? (
                                                <ButtonPrimary
                                                    className={
                                                        "cookies-modal-row-button " +
                                                        (cookiesAccepted !== undefined &&
                                                            !cookiesAccepted &&
                                                            "cookies-modal-button-opacity")
                                                    }
                                                    fluid
                                                    onClick={onAcceptationClick}
                                                    type="success"
                                                >
                                                    {texts.cookiesAcceptButton}
                                                </ButtonPrimary>
                                            ) : (
                                                <Button
                                                    className={
                                                        "cookies-modal-row-button " +
                                                        (cookiesAccepted !== undefined &&
                                                            !cookiesAccepted &&
                                                            "cookies-modal-button-opacity")
                                                    }
                                                    fluid
                                                    onClick={onAcceptationClick}
                                                    type="success"
                                                >
                                                    {texts.cookiesAcceptButton}
                                                </Button>
                                            )}
                                        </Grid.Column>
                                        <Grid.Column className="cookies-modal-row-column" mobile={16} stretched>
                                            {newButtons ? (
                                                <ButtonSecondary
                                                    className={
                                                        "cookies-modal-row-button " +
                                                        (cookiesAccepted !== undefined &&
                                                            cookiesAccepted &&
                                                            "cookies-modal-button-opacity")
                                                    }
                                                    fluid
                                                    onClick={onRefusalClick}
                                                >
                                                    {texts.cookiesRefuseButton}
                                                </ButtonSecondary>
                                            ) : (
                                                <Button
                                                    className={
                                                        "cookies-modal-row-button " +
                                                        (cookiesAccepted !== undefined &&
                                                            cookiesAccepted &&
                                                            "cookies-modal-button-opacity")
                                                    }
                                                    fluid
                                                    onClick={onRefusalClick}
                                                    type="danger"
                                                >
                                                    {texts.cookiesRefuseButton}
                                                </Button>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )}
        </Modal>
    )
}

CookiesNotification.contextTypes = {
    texts: PropTypes.object,
}

CookiesNotification.defaultProps = {
    cookiesAccepted: undefined,
    newButtons: false,
    step: "HOME",
}

CookiesNotification.propTypes = {
    cookiesAccepted: PropTypes.bool,
    newButtons: PropTypes.bool,
    onAcceptationClick: PropTypes.func.isRequired,
    onConfigurationClick: PropTypes.func.isRequired,
    onRefusalClick: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    step: PropTypes.oneOf(["HOME", "CONFIGURATION"]),
}

export default CookiesNotification
